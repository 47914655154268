<template>
  <a-modal
    v-model="visible"
    :footer="null"
    @cancel="cancel"
    width="1200px"
    wrap-class-name="analyze-modal-box"
  >
    <template slot="title">
      <div class="analyze-modal-title">
        <p>国产化替代</p>
        <span>根据您所选的非鸿蒙化产品，鸿联云平台为您推荐SOC、开发板、嵌入式OS三方面的国产化替代参考建议：</span>
      </div>
    </template>
    <div class="analyze-modal-content-box">
      <div class="analyze-modal-content-tab">
        <span :class="{'active':analyzeTabType == 'soc'}" @click="analyzeTab('soc')">SOC</span>
        <span :class="{'active':analyzeTabType == 'dev'}" @click="analyzeTab('dev')">开发板</span>
        <span :class="{'active':analyzeTabType == 'os'}" @click="analyzeTab('os')">嵌入式OS</span>
      </div>
      <div class="analyze-modal-content">
        <div class="analyze-modal-iot-info">
          <p>现用{{analyzeTabType == 'soc'?'SOC':''}}{{analyzeTabType == 'dev'?'开发板':''}}{{analyzeTabType == 'os'?'嵌入式OS':''}}</p>
          <div>
            <template v-if="analyzeTabType == 'soc'">
              <div>
                <span>SOC名称</span>
                <p v-if="val.datSocList">
                  <template v-for="i in val.datSocList">{{i.name}}</template>
                </p>
              </div>
              <div>
                <span>SOC型号</span>
                <p v-if="val.datSocList"><template v-for="i in val.datSocList">{{i.modelNum}}</template></p>
              </div>
              <div>
                <span>所属系列</span>
                <p v-if="val.datSocList"><template v-for="i in val.datSocList">{{i.setNum}}</template></p>
              </div>
            </template>
            <template v-if="analyzeTabType == 'dev'">
              <div>
                <span>开发板名称</span>
                <p>{{val.datDevboard.name}}</p>
              </div>
              <div>
                <span>开发板型号</span>
                <p>{{val.datDevboard.modelNum}}</p>
              </div>
              <div>
                <span>嵌入式OS</span>
                <p><template v-for="i in val.datDevboard.datOs">{{i.name}}</template></p>
              </div>
            </template>
            <template v-if="analyzeTabType == 'os'">
              <div>
                <span>OS名称</span>
                <p><template v-for="i in val.datOsList">{{i.name}}</template></p>
              </div>
              <div>
                <span>系统板本</span>
                <p><template v-for="i in val.datOsList">{{i.sysVersion}}</template></p>
              </div>
              <div>
                <span>适配SOC</span>
                <p><template v-for="i in val.datOsList">{{i.socMapping}}</template></p>
              </div>
            </template>
            <div>
              <span>制造厂商</span>
              <p>{{val.pdtCorpName}}</p>
            </div>
            <div>
              <span>应用场景</span>
              <p>{{val.sceneTagName}}</p>
            </div>
          </div>
        </div>
        <div class="analyze-modal-list-box">
          <p>国产替代</p>
          <div class="analyze-modal-list">
            <div v-for="(item,index) in analyzeList" :key="index" @click="productInfo(item.id)">
              <v-img v-if="analyzeTabType == 'soc'" :attachment-id="item.socImg" :default-img="require('@/assets/img/kctp.png')" />
              <v-img v-else-if="analyzeTabType == 'dev'" :attachment-id="item.attmIds" :default-img="require('@/assets/img/kctp.png')" />
              <v-img v-else :attachment-id="item.osImg" :default-img="require('@/assets/img/kctp.png')" />
              <span>{{item.name}}</span>
              <template v-if="analyzeTabType == 'soc'">
                <div><span>所属系列</span>{{item.setNum}}</div>
                <div><span>硬件型号</span>{{item.modelNum}}</div>
                <div><span>制造厂商</span>{{item.socCorpName}}</div>
              </template>
              <template v-else-if="analyzeTabType == 'dev'">
                <div><span>嵌入式OS</span><template v-for="i in item.datOs">{{i.name}}</template> </div>
                <div><span>型号</span>{{item.modelNum}}</div>
              </template>
              <template v-else>
                <div><span>系统板本</span>{{item.sysVersion}}</div>
                <div><span>适配SOC</span>{{item.socMapping}}</div>
                <div><span>服务商</span>{{item.osCorpName}}</div>
              </template>
              <p>详细参数 ></p>
            </div>
            <span @click="analyzeAll">加载更多</span>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { getIotSocList,getIotDevList,getIotOsList } from "@/api/iot";
import VImg from "@/components/VImg";
export default {
  props: ["show",'val'],
  components:{
    VImg
  },
  data() {
    return {
      visible: false,
      analyzeList:[],
      page:{
        current: 1,
        size: 9,
        pageSize: 1,
      },
      analyzeTabType:'soc'
    };
  },
  mounted() {
    this.visible = this.show;
    this.loadgetIotSocList()
  },
  methods: {
    // SOC列表
    loadgetIotSocList(){
      getIotSocList(this.page,{}).then((res)=>{
        this.analyzeList = [...this.analyzeList,...res.records]
        this.page.pageSize = res.pages
      })
    },
    // 开发板列表
    loadgetIotDevList(){
      getIotDevList(this.page,{}).then((res)=>{
        this.analyzeList = [...this.analyzeList,...res.records]
        this.page.pageSize = res.pages
      })
    },
    // os列表
    loadgetIotOsList(){
      getIotOsList(this.page,{}).then((res)=>{
        this.analyzeList = [...this.analyzeList,...res.records]
        this.page.pageSize = res.pages
      })
    },
    // 切换
    analyzeTab(val){
      this.analyzeTabType = val
      this.page.current = 1
      this.analyzeList = []
      if(val == 'soc'){
        this.loadgetIotSocList()
      }else if(val == 'dev'){
        this.loadgetIotDevList()
      }else{
        this.loadgetIotOsList()
      }
    },
    // 加载跟多
    analyzeAll(){
      let {page,analyzeTabType} = this
      if(page.current == page.pageSize){
        this.$message.warning('暂无更多内容');
        return
      }
      page.current ++
      if(analyzeTabType == 'soc'){
        this.loadgetIotSocList()
      }else if(analyzeTabType == 'dev'){
        this.loadgetIotDevList()
      }else{
        this.loadgetIotOsList()
      }
    },
    cancel(e) {
      this.visible = false;
      this.$emit("input", false);
    },
    productInfo(id){
      let type = this.analyzeTabType
      this.$router.push({
        path: `/replace/productInfo/${type}`,
        query: { id },
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.analyze-modal-title {
  height: 97px;
  background: url(../../assets/img/tb.png) 0 -1px no-repeat;
  background-size: 100% 100%;
  padding: 20px 24px;
  > p {
    line-height: 29px;
    font-size: 30px;
    color: #ffe0b3;
    margin-bottom: 16px;
  }
  > span {
    display: block;
    line-height: 13px;
    font-size: 14px;
    color: #feeace;
    opacity: 0.8;
  }
}
.analyze-modal-content-box {
  .analyze-modal-content-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    > span {
      width: 120px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      transition: all 0.3s;
      &.active {
        background: #EA0B06;
        color: #fff;
      }
    }
  }
  .analyze-modal-content {
    display: flex;
    align-items: flex-start;
    color: #262626;
  }
  .analyze-modal-iot-info {
    width: 240px;
    border: 2px solid #ffffff;
    box-shadow: 0px 6px 12px 0px rgba(0, 65, 152, 0.06);
    margin-right: 24px;
    > p {
      border-bottom: 2px solid #fff;
      background: linear-gradient(0deg, #f7f8fa 0%, #ebeff6 100%);
      height: 48px;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
    }
    > div {
      padding: 16px;
      background: linear-gradient(0deg, #f7f8fa 0%, #ebeff6 100%);
      > div {
        display: flex;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 16px;
        > span {
          width: 64px;
          height: 20px;
          background: #dae0eb;
          border-radius: 10px;
          margin-right: 6px;
          text-align: center;
          color: #595959;
        }
        > p {
          flex: 1;
        }
      }
      > p {
        text-align: center;
        color: #EA0B06;
        font-size: 12px;
        cursor: pointer;
        margin-top: 30px;
      }
    }
  }
  .analyze-modal-list-box{
    width: 888px;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 6px 12px 0px rgba(0, 65, 152, 0.06);
    >p{
      height: 48px;
      border-bottom: 2px solid #FFFFFF;
      background: linear-gradient(0deg, #FFFCF8 0%, #FFE8E6 100%);
      line-height: 48px;
      padding: 0 16px;
      font-size: 16px;
    }
    .analyze-modal-list{
        background: linear-gradient(0deg, #FFFCF8 0%, #FFE8E6 100%);
        max-height: 500px;
        overflow-y: auto;
        padding: 24px 24px 0;
        display: flex;
        flex-wrap: wrap;
        >div{
            width: 257px;
            margin: 0 24px 24px 0;
            background: #fff;
            padding: 16px;
            position: relative;
            overflow: hidden;
            transition: all .3s;
            cursor: pointer;
            &:nth-child(3n){
                margin-right: 0;
            }
            >img{
                width: 80px;
                height: 80px;
                display: block;
                margin-bottom: 16px;
            }
            >span{
                line-height: 12px;
                font-size: 14px;
                display: block;
                margin-bottom: 16px;
            }
            >div{
                display: flex;
                line-height: 11px;
                font-size: 12px;
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
                >span{
                    color: #bfbfbf;
                    margin-right: 16px;
                }
            }
            >p{
                position: absolute;
                left: 0;
                right: 0;
                height: 32px;
                background: #EA0B06;
                color: #fff;
                line-height: 32px;
                text-align: center;
                transition: all .3s;
                bottom: -32px;
            }
            &:hover{
                box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.06);
                >p{
                    bottom: 0;
                }
            }
        }
        >span{
          width: 100%;
          line-height: 14px;
          margin: 0 auto;
          color: #EA0B06;
          cursor: pointer;
          text-align: center;
          margin-bottom: 24px;
        }
    }
  }
}
</style>
<style>
.analyze-modal-box .ant-modal-content {
  border-radius: 0;
}
.analyze-modal-box .ant-modal-header {
  padding: 0;
}
.analyze-modal-box .ant-modal-close-x {
  color: #fff;
  font-size: 20px;
}
.analyze-modal-box .ant-modal-body {
  padding-top: 16px;
}
</style>
